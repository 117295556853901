import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import './login.css'

const Login = ({ baseUrl, setToken }) => {

    const [credential, setCredentail] = useState({ number: "", password: "" })
    const [loader, setLoader] = useState(false)
    const [credentialValidity, setCredentailValidity] = useState(true)

    const navigate = useNavigate()
    const handleChange = (e) => {
        setCredentail({ ...credential, [e.target.name]: e.target.value })
    }
    const handleSubmit = async (e) => {

        try {
            const numberRegex = /^[6-9]\d{9}$/
            const passwordRegex = /^.{6,}$/;
            if (!numberRegex.test(credential.number)) {
                setCredentailValidity(false)
                return
            }
            if (!passwordRegex.test(credential.password)) {
                setCredentailValidity(false)
                return
            }
            if (!navigator.onLine) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You are currently offline. Please check your internet connection and try again.',
                })
                return
            }
            setLoader(true)
            const response = await fetch(`${baseUrl}/authentication`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ number: credential.number, password: credential.password }) })
            const data = await response.json()
            if (data?.statusCode === 200) {
                localStorage.setItem('sprvsrtkn', data?.data[0])
                setToken(data?.data[0])
                navigate('/')
                return
            } else {
                setCredentailValidity((false))
                return
            }
        }
        catch (e) {
            console.error(e)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong on the server!',
            })
        }
        finally {
            setLoader(false)
        }
    }

    return (
        <div className='login-page'>
            {loader ? <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> :
                <div className='container-fluid'>
                    <div className='row d-flex justify-content-center align-items-center '>
                        <div className='col-9 col-sm-8 col-md-6 col-lg-3 card d-flex flex-column justify-content-around login-page-card'>
                            <div className='login-page-ecowrap-logo'>
                                <img src='asset/ecowrap-logo.png' alt='Ecowrap logo' />
                            </div>
                            <div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="floatingInput"
                                        name='number'
                                        value={credential.number}
                                        placeholder="number"
                                        onChange={handleChange}
                                    />
                                    <label
                                        htmlFor="floatingInput">
                                        Contact Number
                                    </label>
                                </div>
                                <div
                                    className="form-floating">
                                    <input type="password"
                                        className="form-control"
                                        id="floatingPassword"
                                        placeholder="Password"
                                        name='password'
                                        value={credential.password}
                                        onChange={handleChange} />
                                    <label
                                        htmlFor="floatingPassword">
                                        Password
                                    </label>
                                </div>
                                {!credentialValidity && <div
                                    style={{ color: 'red', fontSize: '12px' }}>
                                    Incorrect Phone number or password
                                </div>}
                            </div>
                            {/* <Link to={'/forgotPassword'} style={{ marginLeft: 'auto', color: 'blue', fontSize: '14px', textDecoration: 'none' }}>forgot password</Link> */}
                            <div
                                className='login-page-submit'>
                                <button
                                    className='btn btn-primary'
                                    onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default Login