import { Routes, Route, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './App.css';
import Login from './login';
import Home from './home';
import PostJob from './postJob';
function App() {
  const baseUrl = 'https://apis.ecowrap.in/b2g/supervisor'
  const [token, setToken] = useState(localStorage.getItem('sprvsrtkn'))
  const navigate = useNavigate()
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [token, navigate])
  return (
    <div className='app'>
      <Routes>
        <Route path="/login" element={<Login setToken={setToken} baseUrl={baseUrl} navigate={navigate} />} />
        <Route path="/" element={<Home token={token} setToken={setToken} baseUrl={baseUrl} navigate={navigate} />} />
        <Route path="/postJob" element={<PostJob token={token} baseUrl={baseUrl} navigate={navigate} />} />
      </Routes>
    </div>
  );
}

export default App;
