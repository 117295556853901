import React, { useState, useEffect } from 'react';
import './home.css'
function Home({ token, baseUrl, navigate, setToken }) {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch jobs from the API
        const fetchJobs = async () => {
            try {
                const response = await fetch(`${baseUrl}/collection-assignment`, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Send token in Authorization header
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();

                 if (data.statusCode === 200) {
                        setJobs(data.data);
                    }
                 else if (data.statusCode === 401 || data.statusCode===203){
                     
                        navigate('/login');}
                  else{
                    throw new Error('Failed to fetch jobs');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, [token, baseUrl, navigate]);

    const handleLogout = () => {
        localStorage.removeItem('sprvsrtkn');
        setToken(null);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // Format as "MM/DD/YYYY"
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className='container-fluid'>
            <nav className="d-flex flex-row justify-content-between align-items-center">
                <div className="navbar-brand-home">
                    <img src='asset/ecowrap-logo.png' alt="Logo" />
                </div>
                <div className='col-1'>
                    <button className='btn btn-secondary' onClick={handleLogout}>Logout</button>
                </div>
            </nav>

            <main className='home-main mt-4'>
                <div className='add-button-div mb-4'>
                    <button className='btn btn-primary' onClick={() => navigate('/postJob')}>Add Job</button>
                </div>
                <div className='table-container'>
                    <table className='table table-striped table-hover'>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Area Name</th>
                                <th scope="col">Vehicle Number</th>
                                <th scope="col">Inc Name</th>
                                <th scope="col">Helper Name</th>
                                <th scope="col">Driver Name</th>
                                <th scope="col">Category</th>
                                <th scope="col">Status</th>
                                <th scope="col">Collection Date</th>
                                {/* <th scope="col">Actions</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.length > 0 ? (
                                jobs.map((job, index) => (
                                    <tr key={job.assignment_id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{job.area_name}</td>
                                        <td>{job.vehicle_number}</td>
                                        <td>{job.inc_name}</td>
                                        <td>{job.helper_name}</td>
                                        <td>{job.p_name}</td>
                                        <td>{job.category}</td>
                                        <td>{job.status}</td>
                                        <td>{formatDate(job.collection_date)}</td>
                                        {/* <td>
                                        <button className='btn btn-sm btn-success me-2'>Edit</button>
                                        <button className='btn btn-sm btn-danger'>Delete</button>
                                    </td> */}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10">No jobs available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </main>
        </div>

    );
}

export default Home;
